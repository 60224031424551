<template>
    <div>
        <!-- 默认容器名字 router-view【RouterView】 -->
        <router-view></router-view>



        <van-tabbar v-model="active" active-color="#FE8A4F" @change="onChange"  inactive-color="#555555" route>
            <van-tabbar-item  name="home" to="/" icon="home-o">
               首页
            </van-tabbar-item>
            <van-tabbar-item to="/video"  icon="apps-o">
                 综合
            </van-tabbar-item>
            <van-tabbar-item to="/branch" icon="bulb-o">
                网点
            </van-tabbar-item>
           <van-tabbar-item icon="setting-o">
                技术
            </van-tabbar-item>
<!--             <van-tabbar-item to="/code" icon="fire-o">-->
<!--                激活码-->
<!--            </van-tabbar-item>-->

            <van-tabbar-item icon="more-o" @click="toolShowBtn">
              工具
              <ul class="toolbar" v-show="toolShow">
                <li @click="goCode">激活码</li>
                <li @click="maintain">维修订单查询</li>
                <div class="sanj"></div>
              </ul>
            </van-tabbar-item>

            <van-tabbar-item @click="login" icon="friends-o">
                我的
            </van-tabbar-item>
        </van-tabbar>
      <div class="beianhao">
        <div>
          成都东英测量仪器有限公司
          <a style="color: #383b48" href="https://beian.miit.gov.cn/" target="_blank" class="keep-record">蜀ICP备18025635号-4</a>
        </div>
      </div>
        <van-popup v-model="popupShow" round :close-on-click-overlay="false">
            <div class="hint-popup" >
                <div class="hint-title">提示</div>
                <div class="hint-text">使用技术服务请先登录</div>
                <div class="hint-btn">
                    <span @click="cancel">取消</span>
                    <span @click="login">去登陆</span>
                </div>
            </div>
        </van-popup>
    <!-- 拨打电话按钮 -->
        <a id="floatball" @mousedown="down" @touchstart.stop="down" @mousemove="move"
        @touchmove.stop="move" @mouseup="end" @touchend.stop="end" :style="{top:position.y+'px', left:position.x+'px'}"  class="tel-btn iconfont icon-telephone" style="color:#50DB89;" href="tel:400-110-5117"></a>
    </div>
</template>
<script>

import TEMPURL from "../../utils/tempurl";
import leaseApi from "../../utils/leaseApi"
import router from "../../router";
// 鼠标位置和div的左上角位置 差值
var dx, dy
var screenWidth = window.screen.width
var screenHeight = window.screen.height
export default {
    data() {
        return {
            flags: false,
            position: {
                x: screenWidth-60,
                y: screenHeight-330
            },
            active: "home",
            popupShow:false,
            toolShow:false,
        };
    },
    methods: {
          //工具;
          toolShowBtn(){
            this.toolShow = !this.toolShow;
          },
          goCode(){
            this.$router.push({name:'code'})
          },
          maintain(){
            this.$router.push({name:'maintain'})
          },
          /*判断客户端*/
          judgeClient() {
          let u = navigator.userAgent;
          let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;   //判断是否是 android终端
          let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);     //判断是否是 iOS终端
          // console.log('是否是Android：' + isAndroid); //true,false
          // console.log('是否是iOS：' + isIOS);
          if(isAndroid){
            return 'Android';
          }else if(isIOS){
            return 'IOS';
          }else{
            return 'PC';
          }
          },
         // 实现移动端拖拽
            down(event) {
                this.flags = true;
                var touch;
                if (event.touches) {
                    touch = event.touches[0];
                } else {
                    touch = event;
                }
                console.log('鼠标点所在位置', touch.clientX, touch.clientY)
                console.log('div左上角位置', event.target.offsetTop, event.target.offsetLeft)
                dx = touch.clientX - event.target.offsetLeft
                dy = touch.clientY - event.target.offsetTop

            },
            move(event) {
                //实现拖拽
                if (this.flags) {
                    event.preventDefault();
                    var touch;
                    if (event.touches) {
                        touch = event.touches[0];
                    } else {
                        touch = event;
                    }
                    // 定位滑块的位置
                    this.position.x = touch.clientX - dx;
                    this.position.y = touch.clientY - dy;
                    // 限制滑块超出页面
                    // console.log('屏幕大小', screenWidth, screenHeight )
                    if (this.position.x < 0) {
                        this.position.x = 0
                    } else if (this.position.x > screenWidth - touch.target.clientWidth) {
                        this.position.x = screenWidth - touch.target.clientWidth
                    }
                    if (this.position.y < 0) {
                        this.position.y = 0
                    } else if (this.position.y > screenHeight - touch.target.clientHeight) {
                        this.position.y = screenHeight - touch.target.clientHeight
                    }

                }

            },
            //鼠标释放时候的函数
            end() {
                console.log('end')
                this.flags = false;
            },
        // window.localStorage.removeItem('token')
       //  window.localStorage.setItem('token','eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsibWFwbGF5cy1tb2JpbGUtY2xpZW50IiwibWFwbGF5cy1hdXRoY2VudGVyIiwibWFwbGF5cy11c2VyLXNlcnZlciIsIm1hcGxheXMtZ3VhcmFudGVlLXNlcnZlciJdLCJ1c2VyX25hbWUiOiIxNzM1ODUwMTk1OSIsInNjb3BlIjpbImxsbHpqZCIsInVzZXIiLCJ2c2VydmljZSIsImNvbW1vbiJdLCJleHAiOjE2MjUxMzU0ODksImF1dGhvcml0aWVzIjpbIlJPTEVfY29tbW9uIiwiY29tbW9uOnVzZXIiXSwianRpIjoiY2UyNDNhMjQtMzE4OC00N2QxLTliYTUtYWJlZWM0OTE1OTkzIiwiY2xpZW50X2lkIjoibWFwbGF5cyJ9.daN7s_izyu-BzBuV4t7CShAOyoFRDDZzUduylrLQYMY31IjZmmshO1T4m5RrcLYoMo6c4cvcKVrmetJdHckRFwp3Iw9fszZIirdkkYWiW9xlmNtroLsY0lX-QFaEKxN0sGiYrqBnHFxZtTtAXiK7AZ0x5ezo27JQMjYJZ60meTWfsBBf4hiV55hMifXYEjbNQOIj0kZp3LETfUeu2IkAGVgdfN2VwFXuxQdrkzbEkiCipNp3_bF0BXPeJJM9lLJmVpvg3oLwnj_twTBSNgfFA-vo9bvNIf-A3ZUgaeMrPmmXI12_vtzU1VcJfdXNNbx4MEu-32z29V9ve49Dsru57w')
      login() {
          window.localStorage.removeItem('token')
          if (window.localStorage.getItem('token') == null){
            window.location.href = 'http://maplays.net/mobile/api/weiXinLogin'
          }else {
             router.push('/my?hasLogin')
          }

        },
        onChange(index) {
            if(index==3) {
                if(window.localStorage.getItem('token')!=null) {
                    window.location.href= 'https://maplays.net/guarantee/trtc/index.html?roomId=9&userId='+window.localStorage.getItem('phone');
                    this.popupShow=false;
                } else {
                    this.popupShow=true;
                }
            }
        },
        cancel(){
            this.popupShow=false;
            this.$router.go(0)
           // this.$router.push('/');
        }


    }

}
</script>
<style lang="less" scoped>
    .tel-btn {
        position: fixed;
        //bottom: 4.5rem;
        //right: 0.467rem;
        z-index: 999;
        width: 1.28rem;
        height: 1.28rem;
        line-height:1.28rem;
        border-radius: 50%;
        // background: rgb(254, 138, 79);
        text-align: center;
        box-shadow: 0 0.053rem 0.107rem -0.013rem #e2e2e2;
        background: #fff;
        background-size: 400%;
        font-size: 0.64rem;
        color: #333!important;
        text-align: center;
        text-transform: uppercase;
        z-index: 9999;
    }

    .iconfont {
        font-size: 36px;
    }

    .hint-popup {
        .hint-title {
            font-size: 36px;
            color: #333;
            text-align: center;
            padding: 40px 0 0;
        }
        .hint-text {
            font-size: 32px;
            color: #666;
            text-align: center;
            padding: 40px 0;
        }
        .hint-btn {
            display: -webkit-flex;
            display: flex;
            border-top: 1px solid #ddd;
            line-height: 80px;
            span {
                -webkit-flex: 1;
                flex: 1;
                font-size: 28px;
                color: #666;
                text-align: center;
                    &:first-child {
                        border-right:1px solid #ddd;
                    }
                    &:last-child {
                    color:rgb(254, 138, 79);
                    }
            }
        }
    }
    .van-popup--center {
        top: 40%;
        left: 20%;
        width: 60%;
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0);
    }
    .van-tabbar{
      padding-bottom: 1.2rem;
      .van-tabbar-item {
        position: relative;
        .toolbar{
          position: absolute;
          bottom: 1.7rem;
          right: -0.5rem;
          width: 155%;
          background: #fafafa;
          border-radius: 0.1rem;
          li{
            font-size: 0.32rem;
            height: 0.9rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 0.01rem solid #e0e0e0;
            width: 90%;
            margin: 0 auto;
          }
          li:nth-child(2){
            border-bottom: 0;
          }
          .sanj{
            position: absolute;
            bottom: -0.4rem;
            left: calc(50% - 0.25rem);
            width: 0px;                 /*  宽高设置为0，很重要，否则达不到效果 */
            height: 0px;
            border: 0.2rem solid #fafafa;
            border-bottom-color: transparent;    /* 设置透明背景色 */
            border-left-color: transparent;
            border-right-color: transparent;
          }
        }
      }
      //position: inherit!important;
    }

    .badge {
        position: absolute;
        top: 2px;
        left: 50%;
        color: #E71220;
         font-size: 42px;
    }
    .beianhao{
      position: fixed;
      /* left: 50%; */
      bottom: 0.3rem;
      z-index: 2;
      width: 98%;
      font-size: 0.32rem;
      text-align: center;
    }
</style>
