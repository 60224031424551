import request from '@/utils/request'
import qs from "qs";


const leaseApi = {

    getDashBoard() {
        return request.get('/lease/noAuthority/dashBoardData')
    },
    getCategoryData() {
        return request.get('/lease/noAuthority/categoryData')
    },
    getProductGroupBrandByCategory(params) {
        return request.get("/lease/noAuthority/getProductGroupBrandByCategory",params)
    },
    getProductDetails(params) {
        return request.get("/lease/noAuthority/getProductDetails",params)
    },
    falseLogin(){
        return request.get("/lease/noAuthority/testLogin",{})
    },
    getSiteList(){
        return request.get("/lease/needPermission/getSiteList")
    },
    saveSite(data){
        return request.postBody('/lease/needPermission/saveSite',data)
    },
    editSite(data) {
        return request.postBody('/lease/needPermission/updateSite',data)
    },
    deleteSite(data) {
        return request.postBody('/lease/needPermission/deleteSite',qs.stringify(data))
    },
    // 获取所有订单
    getOrderList() {
        return request.get('/lease/needPermission/getOrderList')
    },
    // 获取所有待发已发货订单
    getExpressDelivery() {
        return request.get('/lease/needPermission/getExpressDelivery')
    },
    // 获取所有租赁中，租赁超时订单
    gerLeaseOrder() {
        return request.get('/lease/needPermission/gerLeaseOrder')
    },
    // 获取所有归还中订单
    getReturnOrder() {
        return request.get('/lease/needPermission/getReturnOrder')
    },
    // 获取待评价订单
    getEvaluatedOrder() {
        return request.get('/lease/needPermission/getEvaluatedOrder')
    },
    // 保存评价
    saveEvaluate(data) {
        return request.postBody('/lease/needPermission/saveEvaluate',qs.stringify(data))
    },
    // 查询所有未开票订单
    getOpenTicketOrderList(){
        return request.get('/lease/needPermission/getOpenTicketOrderList')
    },
    //查询所有已开票订单
    getOpenInvoicedList(){
        return request.get('/lease/needPermission/getOpenInvoicedList')
    },
    //查询物流信息
    orderSearch(data){
        return request.postBody('/lease/needPermission/queryDelivery',qs.stringify(data))
    },
//    开票
    openTicket(data) {
        return request.postBody('/lease/needPermission/openTicket',data)
    },
//    获取原订单及所有续费单号
    getOrders(data){
        return request.get('/lease/needPermission/getRenewOrder',data)
    },
//    退租
    returnRent(data){
        return request.uploadPost('/lease/needPermission/returnRent',data)
    },
    //创建首单
    createOrder(data){
        return request.postBody('/lease/needPermission/createOrder',data)
    },
//    获取续单价格
    getReturnRentPrice(data){
        return request.get('/lease/needPermission/getReturnRentPrice',data)
    },
    // 订单个数
    getPersonalData(){
        return request.get('/lease/needPermission/getPersonalData')
    },
    // 用户消息
    getUserMsg(){
        return request.get('/lease/needPermission/getUserMsg')
    },
//    创建续费订单
    createReNewOrder(data){
        return request.postBody('/lease/needPermission/createReNewOrder',data)
    },
    // 提交免押认证
    authenticationIdCard(data){
        return request.uploadPost('/lease/needPermission/authenticationIdCard',data)
    },
    // 获取免押认证数据回显
    authenticationEcho(){
        return request.get('/lease/needPermission/authenticationEcho')
    },
    // 修改免押申请
    authenticationUpdate(data){
        return request.uploadPost('/lease/needPermission/authenticationUpdate',data)
    },
    getStoreSite() {
        return request.get('/lease/needPermission/getStoreSite')
    },
    //查询维修订单接口;
    selectUserFix(data) {
        return request.get('/mobile/api/selectUserFix',data)
    },
    // 线下退租
    returnRentBelow(data){
        return request.uploadPost('/lease/needPermission/returnRentBelow',data)
    },
    //新媒体上传泥人图片接口
    statueUpload(data){
        return request.uploadPost('/mobile/api/statueUpload',data)
    },
    //获取新媒体泥人图片接口
    selectStatue(data){
        return request.get('/mobile/pubApi/selectStatue',data)
    },
}

export default leaseApi
